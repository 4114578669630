let config: { api: string, przelewy24: string };
let regex = {
    phone: /^(\+?\d{1,4}[\s-]?)?(\(?\d{3}\)?[\s-]?)?\d{3}[\s-]?\d{4}$/,
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
};

if (process.env.NODE_ENV === 'production') {
    config = require('../../data/config.json');
} else {
    config = require('../../data/config.dev.json');
}

export { config, regex };
