import axios from "axios";
import { config } from "../configuration";
import { ErrorHandler } from "../errorhandler";

const LoginUser = async (username: string, password: string) => {
    try {
        const response = await axios.post(`${config.api}account/login`, {
            username: username,
            password: password,
        });

        return response.data;
    } catch (error) {
        ErrorHandler(error);
    }
}

const RegisterUser = async (username: string, password: string) => {
    try {
        const response = await axios.post(`${config.api}account/register`, {
            username: username,
            email: `${username}@royalparking.pl`,
            password: password,
        });

        return response.data;
    } catch (error) {
        ErrorHandler(error);
    }
}

const DeleteUser = async (username: string) => {
    try {
        const response = await axios.delete(`${config.api}account/delete/${username}`);

        return response.data;
    } catch (error) {
        ErrorHandler(error);
    }
}

const GetAllUsers = async (): Promise<{ username: string; role: "Admin" | "Root" }[] | undefined> => {
    try {
        const response = await axios.get(`${config.api}account/getall`);

        return response.data;
    } catch (error) {
        ErrorHandler(error);
    }
}

const UpdateUserPassword = async (username: string, password: string) => {
    try {
        const response = await axios.post(`${config.api}account/password`, {
            username: username,
            password: password,
        });

        return response.data;
    } catch (error) {
        ErrorHandler(error);
    }
}

export { LoginUser, RegisterUser, DeleteUser, GetAllUsers, UpdateUserPassword }