import { useMemo, useState } from 'react';
import Card from '../../components/card';
import Input from '../../components/input';
import { GetPriceForDateRange } from '../../services/reservations';

const HomePriceCalculator = () => {
    const GetCurrentDate = () => {
        return new Date().toISOString().split('T')[0];
    };

    const [startDate, setStartDate] = useState<string>(GetCurrentDate());
    const [endDate, setEndDate] = useState<string>(GetCurrentDate());
    const [price, setPrice] = useState<number>(0);

    useMemo(() => {
        const fetchPrice = async () => {
            const result = await GetPriceForDateRange(new Date(startDate), new Date(endDate));
            setPrice(result);
        };

        fetchPrice();
    }, [startDate, endDate]);

    return (
        <div className='w-full flex flex-col justify-center py-8'>
            <span id='price' className='text-3xl text-primary mt-16 mb-4'>
                Kalkulator ceny
            </span>
            <Card>
                <div className='flex lg:flex-row flex-col gap-4 items-center justify-evenly w-full'>
                    <div className='flex flex-row gap-4 items-center justify-center w-full max-w-screen-sm'>
                        <span>Przyjazd</span>
                        <Input type='date' onChange={(e) => setStartDate(e.target.value)} value={startDate} min={GetCurrentDate()} />
                    </div>

                    <div className='flex flex-row gap-4 items-center justify-center w-full max-w-screen-sm'>
                        <span>Odjazd</span>
                        <Input type='date' onChange={(e) => setEndDate(e.target.value)} value={endDate} min={startDate} />
                    </div>

                    <hr className='solid w-full lg:hidden' />

                    <div className='w-full flex flex-col justify-center items-center'>
                        <span className='py-1'>{price ? `Cena: ${price} zł` : 'Wyznacz poprawny okres'}</span>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default HomePriceCalculator;
