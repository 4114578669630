import axios from "axios";
import { CreateReservationDto } from "../../dtos/CreateReservationDto";
import { config } from "../configuration";
import { ErrorHandler } from "../errorhandler";

const RegisterTransaction = async (reservation: CreateReservationDto) => {
    try {        
        const response = await axios.post(`${config.api}transaction/register`, reservation);
        
        return response.data;
    } catch (error) {
        ErrorHandler(error);
    }
};

export { RegisterTransaction }