import { createContext, useEffect, useState } from 'react';
import { User } from '../models/User';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import React from 'react';
import axios from 'axios';
import { LoginUser, RegisterUser } from '../services/authentication';
import { ErrorHandler } from '../services/errorhandler';

type UserContextType = {
    authUser: User | null;
    token: string | null;
    registerUser: (email: string, username: string) => void;
    loginUser: (username: string, password: string) => void;
    logout: () => void;
    isLoggedIn: () => boolean;
};

type Props = { children: React.ReactNode };

const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({ children }: Props) => {
    const navigate = useNavigate();

    const [token, setToken] = useState<string | null>(null);
    const [authUser, setAuthUser] = useState<User | null>(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const authUser = localStorage.getItem('authUser');
        const token = localStorage.getItem('token');
        if (authUser && token) {
            setAuthUser(JSON.parse(authUser));
            setToken(token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        }
        setIsReady(true);
    }, []);

    const registerUser = async (username: string, password: string) => {
        await RegisterUser(username, password)
            .then(() => {
                navigate('/account/manage');
            })
            .catch((err) => {
                ErrorHandler(err);
            });
    };

    const loginUser = async (username: string, password: string) => {
        let loadingToast = toast.loading('Verifying credentials...');

        await LoginUser(username, password)
            .then((data) => {
                if (data) {
                    localStorage.setItem('token', data.token);
                    const userObj = {
                        userName: data.userName,
                        email: data.email,
                    };
                    localStorage.setItem('authUser', JSON.stringify(userObj));
                    setToken(data.token!);
                    setAuthUser(userObj!);

                    setTimeout(() => {
                        toast.dismiss(loadingToast);
                        toast.success('Login Success!');
                        navigate('/reservations/manage');
                    }, 500);
                }
            })
            .catch((err) => {
                ErrorHandler(err);
            });
    };

    const isLoggedIn = () => {
        return !!authUser;
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('authUser');
        setAuthUser(null);
        setToken('');
        navigate('/account/login');
    };

    return <UserContext.Provider value={{ loginUser, authUser, token, logout, isLoggedIn, registerUser }}>{isReady ? children : null}</UserContext.Provider>;
};

export const useAuth = () => React.useContext(UserContext);
