enum Theme {
    dark = 'dark',
    light = 'light',
}

const GetTheme = (): Theme => {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        return Theme.dark;
    } else {
        return Theme.light;
    }
};

const SetTheme = (theme: Theme) => {
    localStorage.theme = theme;

    if (theme === Theme.dark) {
        document.documentElement.classList.add('dark');
    } else {
        document.documentElement.classList.remove('dark');
    }
};

const ToggleTheme = () => {
    const theme = GetTheme();
    const targetTheme = theme === Theme.dark ? Theme.light : Theme.dark;

    SetTheme(targetTheme);
};

export { GetTheme, ToggleTheme };
