import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const Rules = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: '/legal/rules',
            title: 'Regulamin',
        });
    }, []);

    return (
        <div className="text-start flex flex-col justify-start">
            <h1 className="text-3xl text-center p-8 text-primary font-bold mb-4">Regulamin</h1>

            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">1. Informacje podstawowe</h2>
                <p>1.1. Niniejszy regulamin świadczenia usług przez Royal Parking – Tomasz Szymczyk przy ul. Baletowej 113C 02-867, Warszawa, NIP: 1230203368, REGON: 017443106 oraz adres poczty elektronicznej:. Royalparking.kontakt@gmail.com (dalej „Firma”), określa:</p>
                <p className="mx-4">1.1.1. zasady oraz warunki funkcjonowania serwisu internetowego działającego pod domeną www.RoyalParking.pl (dalej „Serwis”);</p>
                <p className="mx-4">1.1.2. zasady oraz warunki na jakich osoby zainteresowane rezerwacją Usług wykonywanych na parkingu (dalej „Klienci”) mogą dokonać rezerwacji Usług za pośrednictwem Serwisu;</p>
                <p className="mx-4">1.1.3. zasady oraz warunki świadczenia przez Firmę Usług wykonywanych na parkingu</p>
                <p className="mx-4">1.1.4. zasady oraz warunki na jakich Firma będzie sprzedawać Klientom Usługi zakupione w Serwisie.</p>
                <p>1.2. Jeżeli nic innego nie wynika z treści Regulaminu, wyrazy pisane w Regulaminie wielką literą będą posiadały następujące znaczenie:</p>
                <p className="mx-4">1.2.1. Serwis – serwis internetowy działający pod domeną wwwRoyalParking.pl</p>
                <p className="mx-4">1.2.2. Klient – osoba korzystająca z Serwisu, w tym również telefonicznie, zainteresowana rezerwacją Usługi wykonywanej na Parkingu lub zakupem Usługi w Serwisie, jak również osoba, w imieniu której, rezerwacji lub zakupu Usługi dokonuje inny podmiot.</p>
                <p className="mx-4">1.2.3. Parkingi – parkingi na których Firma świadczy swoje usługi</p>
                <p className="mx-4">1.2.4. Sprzedaż Usług – obejmująca sytuację, w której Klient nabywa Usługę bezpośrednio od Firmy i dokonuje zapłaty wynagrodzenia za Usługi w Serwisie;</p>
                <p className="mx-4">1.2.5. Usługi – usługi oferowane przez Firmę klientom, polegające na wykonywaniu Usług Parkingowych</p>
                <p className="mx-4">1.2.6. Usługa Parkingowa – usługa oferowana przez Firmę Klientom, polegająca na umożliwieniu im pozostawienia pojazdu na miejscu postojowym na Parkingu, wykonywana w formie Umowy najmu miejsca postojowego.</p>
                <p>1.3. Serwis umożliwia Klientom, działającym osobiście lub za pośrednictwem podmiotów współpracujących z Firmą, rezerwację Usług wykonywanych na terenie Parkingu.</p>
                <p>1.4. Firma świadczy Usługi na podstawie Regulaminu.</p>
                <p>1.5. W zakresie rezerwacji Usług Firma oświadcza i informuje Klientów, że jest właścicielem i operatorem Parkingu.</p>
                <p>1.6. Firma informuje Klientów, że w ramach Usług Parkingowych Firma oferuje swoim klientom miejsca postojowe dozorowane.</p>
                <p>1.7. Dokonanie rezerwacji Usługi za pośrednictwem Serwisu, telefonicznie lub za pośrednictwem podmiotu współpracującego z Firmą jest bezpłatne dla Klienta.</p>
                <p>1.8. Serwis chroniony jest prawami autorskimi w rozumieniu przepisów ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (Dz.U. z 2006 r., Nr 90, poz. 631, z późn. zm.) przysługującymi Firmie.</p>
            </section>
            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">2. Zasady ogólne rezerwacji oraz zakupu Usług</h2>
                <p>2.1. Przed dokonaniem rezerwacji Usługi za pomocą Serwisu lub przed jej zakupem w Serwisie Klient powinien pobrać i zapisać na dysku twardym swojego komputera niniejszy Regulamin.</p>
                <p>2.2. Korzystanie z Serwisu jest równoznaczne z zapoznaniem się i akceptacją przez Klienta niniejszego Regulaminu, co Klient potwierdza podczas dokonywania rezerwacji lub zakupu Usługi, w sposób określony w Regulaminie oraz poprzez podpisanie oświadczenie o zapoznaniu się z Regulaminem przed rozpoczęciem korzystania z Usług.</p>
                <p>2.3. Korzystanie z Serwisu powinno następować w swoim imieniu i na swoją rzecz. Jeżeli następuje to w imieniu osoby trzeciej, osoba korzystająca z Serwisu powinna posiadać skutecznie udzielone oraz ważne w świetle przepisów prawa pełnomocnictwo do działania w imieniu i na rzecz danej osoby trzeciej. Za działanie bez stosownego pełnomocnictwa lub z przekroczeniem jego zakresu osoba korzystająca z Serwisu może ponosić odpowiedzialność prawną zarówno względem osoby trzeciej, jak również względem Firmy.</p>
                <p>2.4. Dokonywanie rezerwacji Usług za pośrednictwem Serwisu lub zakupu Usług w Serwisie z użyciem fałszywego nazwiska lub z użyciem cudzego nazwiska bez ważnego pełnomocnictwa jest zabronione poprzez powszechnie obowiązujące przepisy prawa, a ponadto będzie skutkować brakiem możliwości wykonania Usług dla osoby, która nie posłużyła się swoimi rzeczywistymi danymi.</p>
                <p>2.5. Dokonanie rezerwacji Usług Parkingu oznacza akceptację przez Klienta oferty zawarcia odpowiedniej umowy z podmiotem prowadzącym dany Parking, ceny wykonania Usług oraz regulaminu danego Parkingu i jego lokalizacji.</p>
            </section>
            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">3. Informacje dla Klientów będących konsumentami</h2>
                <p>3.1. W przypadku gdy Klient jest konsumentem, zawierane z Parkingami umowy o wykonanie Usług, w tym w szczególności najmu miejsc postojowych, jak również zawierane z Firmą umowy o wykonanie Usług, zawierane są na odległość, w rozumieniu art. 2 pkt 1 ustawy z dnia 30 maja 2014 r. o prawach konsumentów (Dz.U. z 2014 r., poz. 827).</p>
                <p>3.2. Informacje, o których Klient będący konsumentem powinien zostać poinformowany przez Firma najpóźniej w chwili wyrażenia woli związania się na odległość umową o wykonanie Usług przekazywane są Klientom za pośrednictwem Serwisu, telefonicznie oraz za pośrednictwem podmiotów współpracujących z Firmą, przy czym Firma, niezależnie od powyższego, informuje Klientów, że:</p>
                <p className="mx-4">3.2.1. dane identyfikujące Firmę, w tym jej dane rejestrowe, adresowe i kontaktowe, zostały wskazane na wstępie Regulaminu, przy czym Firma wskazuje, że Klienci mogą kontaktować się z Firmą również za pośrednictwem poczty elektronicznej, z wykorzystaniem adresu poczty elektronicznej:Royalparking.kontakt@gmail.com, lub telefonicznie, pod numerem telefonu: 783 773 773 , przy czym biuro Firmy pracuje w dni robocze w godzinach od 8:00 do 16:00;</p>
                <p className="mx-4">3.2.2. reklamacje dotyczące Usług Klienci mogą składać Firmie na zasadach określonych w § 7 Regulaminu;</p>
                <p className="mx-4">3.2.3. niezwłocznie po zawarciu umowy o wykonanie Usługi Firma będzie przekazywała Klientom potwierdzenie jej zawarcia na adres mailowy podany podczas rezerwacji przez Klienta.</p>
                <p>3.3. Szczegółowe zasady i warunki wykonywania Usług przez Parking określone są w niniejszym Regulaminie, dostępnym na stronie internetowej oraz na Parkingu.</p>
            </section>
            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">4. Ochrona danych osobowych Klientów</h2>
                <p>4.1. Dane osobowe wprowadzone przez Klientów do Serwisu, tj. imię, nazwisko, adres e-mail oraz/lub numer telefonu kontaktowego (dalej „Dane osobowe”), będą przetwarzane przez Royal Parking – Tomasz Szymczyk. zgodnie z przepisami rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/45/WE (ogólne rozporządzenie o ochronie danych), dalej „RODO”.</p>
                <p>4.2. Royal Parking – Tomasz Szymczyk zbiera Dane osobowe Klientów w celu wykonania Usługi, w celu rozpatrzenia ewentualnych reklamacji, w celu dokonywania rezerwacji lub sprzedaży Usług, jak również w celu umożliwienia zawarcia między Klientem a Firmą umowy o wykonanie Usługi, w tym umowy najmu miejsca postojowego.</p>
                <p>4.3. Royal Parking – Tomasz Szymczyk może przetwarzać Dane osobowe tj. Numer telefonu, adres email Klienta w celach marketingowych, w razie zaznaczenia pola „Wyrażam zgodę na otrzymywanie informacji handlowych e-mail i sms. na zasadzie prawnie uzasadnionego interesu administratora danych. Klient ma prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzania jego Danych osobowych do celów marketingu bezpośredniego.</p>
                <p>4.4. Dane osobowe Klientów mogą być ujawnione podmiotom świadczącym usługi w zakresie utrzymania i serwisu systemów informatycznych Firmy, w tym dostawcom usług hostingu, na podstawie stosownych umów zawartych przez Firmy z tymi podmiotami.</p>
            </section>
            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">5. Okres przechowywania danych osobowych</h2>
                <p>5.1. Dane osobowe Klientów będą przechowywane przez okres niezbędny do wykonania obowiązków umownych i ustawowych związanych ze świadczonymi Usługami, w szczególności dla celów prowadzenia dokumentacji sprzedaży Usług zgodnie z obowiązującymi przepisami prawa i rozpatrzenia ewentualnych reklamacji. Dane osobowe przetwarzane w celach marketingowych możemy przechowywać do momentu wniesienia sprzeciwu przez osobę, której te dane dotyczą.</p>
                <p>5.2. Podanie przez Klientów Danych osobowych jest dobrowolne, jednakże niezbędne w celu zawarcia umowy i prawidłowej realizacji Usługi przez Royal Parking – Tomasz Szymczy</p>
                <p>5.3. W każdej chwili Klienci mają prawo żądania dostępu do treści ich Danych osobowych zgromadzonych w Serwisie, ich sprostowania, usunięcia lub ograniczenia przetwarzania, jeżeli są one niekompletne, nieaktualne, nieprawdziwe lub zostały zebrane z naruszeniem przepisów prawa powszechnie obowiązującego albo są już zbędne do realizacji celu, dla którego zostały zebrane, prawo wniesienia sprzeciwu, a także prawo do przenoszenia danych.</p>
                <p>5.4. Jeżeli Klient uzna, że jego Dane osobowe są przetwarzane z naruszeniem obowiązujących przepisów, Klientowi przysługuje prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.</p>
            </section>
            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">6. Dokonywanie rezerwacji lub zakupu Usług</h2>
                <p>6.1. Klienci mogą dokonywać rezerwowania lub zakupu Usług z wykorzystaniem Serwisu poprzez sieć Internet, jak również za pośrednictwem podmiotów współpracujących z Firmą, przy czym, jeżeli Regulamin nie stanowi inaczej, do dokonywania rezerwacji lub zakupu Usług za pośrednictwem podmiotów współpracujących z Firmą stosuje się odpowiednio zasady dotyczące rezerwowania lub zakupu Usług z wykorzystaniem Serwisu, a dane dostępne w Serwisie są przekazywane Klientom przez podmioty współpracujące z Firmą.</p>
                <p>6.2. Klienci mogą ponadto dokonywać rezerwowania Usług telefonicznie, pod numerem telefonu 783 773 773</p>
                <p>6.3. Korzystając z Serwisu, tak bezpośrednio, jak również za pośrednictwem podmiotu współpracującego z Firmą, Klienci określają parametry miejsca i czasu, w którym chcieliby skorzystać z Usług na terenie Parkingu.</p>
                <p>6.4. W przypadku wyboru kanału kontaktu telefonicznego Klient przekazuje konsultantowi parametry miejsca i czasu, w którym chciałyby skorzystać z Usług, po czym konsultant zbiera od Klienta dane, oświadczenia i żądania niezbędne do dokonania rezerwacji Usług oraz przekazuje mu wszelkie informacje niezbędne do zakończenia tego procesu lub wymagane przez przepisy prawa powszechnie obowiązującego, w tym w szczególności dane o głównych cechach Usług, o sposobie płatności wynagrodzenia za Usługi, jak również informacje o które zapyta Klient, a które są związane z realizacją Usługi.</p>
                <p>6.5. Po zakończeniu procesu rezerwowania Usług za pośrednictwem Serwisu Klienci otrzymają od Firmy, na wskazany adres e-mail, potwierdzenie dokonania rezerwacji Usług, które wysyłane jest niezwłocznie, jednak nie później niż w ciągu 1 (jednego) dnia roboczego od dnia zakończenia procesu rezerwowania Usług.</p>
            </section>
            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">7. Reklamacje dotyczące Usługi Pośredniczenia lub Sprzedaży Usługi</h2>
                <p>7.1. Klient może złożyć reklamację, jeżeli Usługa oferowana w ramach Serwisu, której zasady świadczenia przez Firmę określa Regulamin, nie jest realizowana przez Firmy lub jest realizowana niezgodnie z postanowieniami Regulaminu.</p>
                <p>7.2. Reklamacje dotyczące świadczenia przez Firmę Usługi należy składać do Royal Parking – Tomasz Szymczyk za pośrednictwem poczty elektronicznej, z wykorzystaniem adresu poczty elektronicznej: Royalparking.kontakt@gmail.com, lub, w dni robocze, w godzinach pracy biura Firmy, tj. w godzinach od 8:00 do 16:00, telefonicznie, pod numerem telefonu: 783 773 773</p>
                <p>7.3. Prawidłowo zgłoszona reklamacja powinna zawierać co najmniej dane Klienta zgłaszającego reklamację, w tym imię, nazwisko, adres e-maili lub numer telefonu kontaktowego oraz powinna wskazywać wady lub nieprawidłowości w działaniu Serwisu lub w zakresie świadczenia przez Firmę Usługi lub opis zastrzeżeń.</p>
                <p>7.4. Oceny złożonej reklamacji, w tym w szczególności jej zasadności, dokonuje pracownik lub upoważniony przedstawiciel Firmy w terminie 14 dni od dnia otrzymania reklamacji.</p>
                <p>7.5. Odpowiedź na reklamację, zawierająca stanowisko Firmy co do zasadności wniesionej reklamacji, jest wysyłana Klientowi w terminie wskazanym w pkt. 5.4. powyżej za pomocą wiadomości e-mail lub telefonicznie.</p>
                <p>7.6. W przypadku dokonania przez Klienta zakupu Usług za pośrednictwem Serwisu Klient powinien wydrukować potwierdzenie przelewu wynagrodzenia za Usługi, tak aby mógł przedstawić je pracownikom Parkingu.</p>
                <p>7.7. Konsument posiada możliwość rezygnacji z umowy i zwrotu środków maksymalnie 24h przed datą rezerwacji, rezygnacja w czasie mniejszym niż 24h przed wykonaniem usługi nie uprawnia do zwrotu środków rezygnacj</p>
            </section>
            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">8. Obowiązki stron</h2>
                <p>8.1. Każdy wjeżdżając na teren parkingu wyraża bezwarunkową zgodę na postanowienia niniejszego “Regulaminu” i zobowiązuje się do ścisłego przestrzegania jego postanowień.</p>
                <p>8.2. Regulamin dostępny jest do wglądu w biurze obsługi parkingu, oraz na stronie internetowej www.RoyalParking.pl</p>
                <p>8.3. RoyalParking NIP: 1230203368 , właściciel parkingu dozorowanego przy Ul. Baletowej 113C 02-867 Warszawa (zwany w dalszej części Właścicielem), zawiera umowy najmu miejsc postojowych z klientem (zwanym w dalszej części Użytkownikiem) na zasadach określonych niniejszymi warunkami i przepisami Kodeksu Cywilnego.</p>
                <p>8.4. Zawarcie umowy najmu miejsca postojowego przez Użytkownika parkingu z Właścicielem parkingu następuje z chwilą zapłaty za korzystanie z miejsca na terenie parkingu (czego dowodem jest karta parkingowa) lub pozostawienia pojazdu.</p>
                <p>8.5. Zawarcie umowy najmu miejsca postojowego jest równoważne z przyjęciem przez Użytkownika wszystkich warunków umowy najmu (o treści określonej w obowiązującym Kodeksie Cywilnym) oraz zobowiązania się do bezwarunkowego przestrzegania regulaminu usług.</p>
                <p>8.6. Parking jest czynny całą dobę przez wszystkie dni w roku. Jednakże Właściciel parkingu zastrzega sobie prawo do wprowadzania zmian w zakresie czasu otwarcia parkingu, bez podania przyczyny.</p>
                <p>8.7. Parking jest płatny, dozorowany.</p>
                <p>8.8. Opłaty za parkowanie pobierane są z góry (przed wydaniem karty parkingowej) w systemie dobowym, według cennika ustalonego przez Właściciela.</p>
                <p>8.9. Opłata za parkowanie naliczana jest za każdą rozpoczętą dobę.</p>
                <p>8.10. Wykupując miejsce na terenie parkingu, Użytkownik jest zobowiązany bez wezwania:</p>
                <p className="mx-4">8.10.1. do udostępnienia obsłudze parkingu oględzin pojazdu. Niedopełnienie powyższego obowiązku skutkuje bezwarunkowym wyłączeniem odpowiedzialności Właściciela parkingu za pojazd Użytkownika.</p>
                <p className="mx-4">8.10.2. do udostępnienia obsłudze parkingu oględzin pojazdu. Niedopełnienie powyższego obowiązku skutkuje bezwarunkowym wyłączeniem odpowiedzialności Właściciela parkingu za pojazd Użytkownika.</p>
                <p>8.11. Po opłaceniu należności za parkowanie, Użytkownik podpisuje stanowiące część karty parkingowej oświadczenie o zapoznaniu się z Regulaminem, po czym otrzymuje jej zawierający m. in. datę wjazdu i wyjazdu, godziny wjazdu i przybliżoną godzinę wyjazdu, telefon kontaktowy do BOP (Biuro Obsługi Parkingu),nr rejestracyjny pojazdu Użytkownika, nazwisko Użytkownika, których zgodność Użytkownik jest zobowiązany sprawdzić bez upominania ze strony pracownika parkingu.</p>
                <p>8.12. Odbiór pojazdu następuje wyłącznie za uprzednim zwrotem oryginału karty parkingowej. Oryginał karty jest jedynym dokumentem upoważniającym Użytkownika do odbioru pojazdu, a Właściciela parkingu do wydania pojazdu okazicielowi oryginału karty parkingowej</p>
                <p>8.13. W przypadku zagubienia lub utraty oryginału karty, pojazd może być wydany Użytkownikowi tylko i wyłącznie, po uprzednim: okazaniu dowodu osobistego oraz jednocześnie innego dokumentu ze zdjęciem stwierdzającego tożsamość Użytkownika, okazaniu dowodu rejestracyjnego odbieranego pojazdu, złożeniu pisemnego pokwitowania potwierdzającego odbiór pojazdu.</p>
                <p>8.14. Wydanie pojazdu osobie trzeciej, nie będącej Użytkownikiem, może nastąpić tylko i wyłącznie po uprzednim: zwrocie oryginału karty parkingowej, okazaniu dowodu osobistego oraz jednocześnie innego dokumentu ze zdjęciem stwierdzającego tożsamość osoby trzeciej, okazaniu dowodu rejestracyjnego odbieranego pojazdu, 4. złożeniu pisemnego pokwitowania potwierdzającego odbiór pojazdu, okazaniu ważnego (aktualnego) upoważnienia lub pełnomocnictwa w tym zakresie wystawionego osobie trzeciej przez Użytkownika potwierdzeniu telefonicznym przez Użytkownika faktu próby odbioru pojazdu przez osobę trzecią</p>
                <p>8.15. Każdy kierowca pojazdu wjeżdżający na teren parking bezwarunkowo zobowiązany jest:</p>
                <p className="mx-4">8.15.1. zatrzymać się przed szlabanem i poczekać na jego otwarcie przez obsługę parkingu</p>
                <p className="mx-4">8.15.2. pamiętać, że szlaban zamyka się natychmiast po każdym przejechanym pojeździe (jednoczesny przejazd dwóch pojazdów grozi uszkodzeniem samochodu i szlabanu, za co odpowiedzialnością oraz których to kosztami naprawy obciążony zostanie wyłącznie sprawca zdarzenia)</p>
                <p>8.16. Każdy kierowca pojazdu (Użytkownik) korzystający z parkingu bezwarunkowo zobowiązany jest do przestrzegania:</p>
                <p className="mx-4">8.16.1. znaków drogowych pionowych i poziomych,</p>
                <p className="mx-4">8.16.2. poleceń obsługi parkingu oraz innych osób lub służb posiadających uprawnienie w tym zakresie (Policja, Żandarmeria Wojskowa itp.)</p>
                <p>8.17. Użytkownik pozostawia pojazd wyłącznie w sposób i w miejscu wskazanym przez obsługę parkingu, wyłączając odbiorniki prądu (przez wyjęcie kluczyków ze stacyjki), wygaszając ewentualne niedopałki, zamykając okna, drzwi, bagażnik, uruchamiając alarm lub inne urządzenie zabezpieczające pojazd przed włamaniem, kradzieżą lub inną szkodą; za wyjątkiem szkody spowodowanej przez innego Użytkownika parkingu (np. podczas wykonywania przez niego manewrów parkowania lub wszelkich innych manewrów), za którą to szkodę bezwarunkową i wyłączną odpowiedzialność ponosił będzie sprawca zdarzenia.</p>
                <p>8.18. W czasie wykonywania na parkingu manewrów parkowania Użytkownik (kierowca pojazdu) musi bezwzględnie uważać na pojazdy sąsiednie i ustawiać pojazd w miejscach wskazanych wyłącznie przez obsługę parkingu. Niedochowanie należytej staranności, bądź niezastosowanie się do poleceń obsługi skutkuje obciążeniem Użytkownika bezwarunkową i pełną odpowiedzialnością za poczynione szkody i wyłącza odpowiedzialność Właściciela parkingu.</p>
                <p>8.19. Stwierdzając szkodę (wszelkie uszkodzenia pojazdów, budynków i innych rzeczy) powstałą podczas wykonywania manewrów parkowania lub wszelkich innych manewrów wykonywanych przez Użytkownika, Użytkownik niezwłocznie zobowiązany jest powiadomić o tym fakcie obsługę parkingu, pozostawić pojazd na parkingu do czasu dokonania oględzin przez Właściciela, obsługę parkingu lub uprawnione służby(Policja, Straż Miejska itp.). Niedopełnienie chociażby jednego z powyższych wymogów, jest równoznaczne z bezwarunkowym przyznaniem się do winy przez Użytkownika w zakresie spowodowania powstania szkody podczas wykonywania na terenie parkingu manewrów parkowania lub wszelkich innych manewrów przez Użytkownika i wyłącza odpowiedzialność Właściciela parkingu.</p>
                <p>8.20. Właściciel parkingu nie ponosi odpowiedzialności w szczególności za:</p>
                <p className="mx-4">8.20.1. utratę lub uszkodzenie znajdujących się na terenie parkingu pojazdów mechanicznych i rzeczy w nich pozostawionych,</p>
                <p className="mx-4">8.20.2. rzeczy pozostawione w pojeździe nie zgłoszone obsłudze parkingu do wpisu w karcie parkingowej,</p>
                <p className="mx-4">8.20.3. szkody powstałe podczas wykonywania na terenie parkingu manewrów parkowania lub wszelkich innych manewrów przez Użytkownika (nawet jeśli był prowadzony i/lub naprowadzany przez obsługę parkingu),</p>
                <p className="mx-4">8.20.4. szkody spowodowane siłą wyższą (gradobicie, powódź, wichura, intensywne opady deszczu, itp.),</p>
                <p className="mx-4">8.20.5. szkody wyrządzone przez gryzonie, ptactwo i inne zwierzęta nie będące zwierzętami należącymi do Właściciela parkingu</p>
                <p>8.21. Właściciel nie ponosi odpowiedzialności za żadne szkody powstałe podczas korzystania z parkingu,</p>
                <p>8.22. O kradzieży lub włamaniu Użytkownik jest zobowiązany bezzwłocznie (natychmiast) powiadomić: obsługę parkingu, właściwą jednostkę Policji</p>
                <p>8.23. Nie przestrzeganie postanowień Regulaminu skutkuje bezwarunkowym wyłączeniem odpowiedzialności Właściciela parkingu oraz możliwość nałożenia sankcji karnych przez Policję (lub przez inne uprawnione do tego służby) w uzasadnionych przypadkach.</p>
                <p>8.24. Dane do faktury należy podać podczas składania rezerwacji poprzez formularz rezerwacji na naszej stronie internetowej, lub obsłudze Parkingu bezpośrednio po wjeździe na parking. Faktury wystawiamy i drukujemy na życzenie klienta po jego powrocie z lotniska.</p>
                <p>8.25. Po wyjeździe z parkingu wszelkie reklamacje dotyczące utraty rzeczy lub jakichkolwiek uszkodzeń mogących mieć miejsce na terenie parkingu nie będą rozpatrywane. Monitoring jest przechowywany przez okres około 2 tygodni, w przypadku gdy Klient chce sprawdzić monitoring, musi osobiście się udać do Biura Parkingu przed upływem tego terminu. Właściciel nie ma możliwości zachowania materiału wideo monitoringu.</p>
                <p>8.26. W ramach wykonywania Usługi Parkingowej, Właściciel parkingu zapewnia bezpłatny transfer Klientom z parkingu na lotnisko Warszawa Okęcie im. Chopina i z powrotem.</p>
                <p>8.27. Sądem właściwym dla dochodzenia roszczeń wynikających z wykonywania umowy najmu miejsca postojowego jest każdorazowo Sąd właściwy miejscowo dla Właściciela Parkingu.</p>
            </section>
            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">9. Postanowienia końcowe</h2>
                <p>9.1. W sprawach nieuregulowanych w postanowieniach Regulaminu zastosowanie mają przepisy prawa powszechnie obowiązującego, w tym w szczególności przepisy Kodeksu cywilnego.</p>
                <p>9.2. Zmiany Regulaminu dokonywane będą przez Royal Parking – Tomasz Szymczyk poprzez opublikowanie nowej treści Regulaminu na stronie internetowej Serwisu, przy czym zmieniony Regulamin nie będzie miał zastosowania do umów o wykonanie Usług zawartych pomiędzy Firmą a Klientem do dnia opublikowania na stronie internetowej Serwisu nowego Regulaminu, co oznacza, że do umów o wykonanie Usług zawartych przed tym dniem, aż do dnia ich rozwiązania lub wygaśnięcia, stosować się będzie Regulamin w brzmieniu obowiązującym w dniu ich zawarcia</p>
            </section>
        </div>
    );
};

export default Rules;
