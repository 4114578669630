import { Route, Routes } from 'react-router-dom';
import Footer from './layouts/Footer';
import Navbar from './layouts/Navbar';
import Home from './pages/home/Home';
import ReservationForm from './pages/reservations/form/ReservationForm';
import ReservationComplete from './pages/reservations/completed/ReservationComplete';
import Rules from './pages/legal/rules/Rules';
import PrivacyPolicy from './pages/legal/privacypolicy/PrivacyPolicy';
import Login from './pages/account/login/Login';
import { UserProvider } from './context/useAuth';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { GetTheme } from './services/theme';
import AccountManage from './pages/account/manage/AccountManage';
import CreateAccount from './pages/account/create/CreateAccount';
import ReservationManager from './pages/reservations/manage/ReservationsManager';
import ReactGA from 'react-ga4';

function App() {
    ReactGA.initialize('G-FNQDNQ9XHR');

    return (
        <>
            <UserProvider>
                <div className='min-h-screen bg-background text-foreground text-sm flex flex-col justify-between'>
                    <Navbar />
                    <div className='text-center flex flex-col justify-center pb-16 lg:px-32 md:px-16 sm:px-8 px-4'>
                        <Routes>
                            <Route path='/'>
                                <Route index element={<Home />} />

                                <Route path='reservations'>
                                    <Route path='form' element={<ReservationForm />} />
                                    <Route path='completed' element={<ReservationComplete />} />
                                    <Route path='manage' element={<ReservationManager />} />
                                </Route>

                                <Route path='legal'>
                                    <Route path='privacypolicy' element={<PrivacyPolicy />} />
                                    <Route path='rules' element={<Rules />} />
                                </Route>

                                <Route path='account'>
                                    <Route path='login' element={<Login />} />
                                    <Route path='create' element={<CreateAccount />} />
                                    <Route path='manage' element={<AccountManage />} />
                                </Route>
                            </Route>
                        </Routes>
                    </div>
                    <Footer />
                </div>
            </UserProvider>

            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme={GetTheme()}/>
        </>
    );
}

export default App;
