import { useEffect, useState } from "react";
import Button from "../../../components/button";
import Card from "../../../components/card";
import Input from "../../../components/input";
import { useAuth } from "../../../context/useAuth";
import ReactGA from "react-ga4";

const Login = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/account/login",
            title: "Account Login",
        });
    }, []);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const { loginUser } = useAuth();

    const handleLogin = () => {
        loginUser(username, password);
    };

    return (
        <div className="flex flex-col justify-center items-center w-full text-start">
            <Card className="sm:max-w-screen-sm w-full">
                <div className="flex flex-col gap-1.5 p-2 w-full">
                    <span className="text-lg">Login</span>
                    <Input className="w-full" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                </div>

                <div className="flex flex-col gap-1.5 p-2 w-full">
                    <span className="text-lg">Hasło</span>
                    <Input className="w-full" name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>

                <hr className="w-full my-4 border-t" />

                <Button className="w-full" onClick={handleLogin}>
                    Zaloguj
                </Button>
            </Card>
        </div>
    );
};

export default Login;
