const HomeLocation = () => {
    return (
        <div className='flex flex-col w-full justify-center py-8'>
            <span id='about-us' className='text-3xl text-primary mt-16 mb-4'>
                Lokalizacja
            </span>
            
            <div className="border-8 border-card rounded-lg bg-card justify-center">
                <iframe title="location" id="location" className="w-full h-96 rounded-lg dark:invert-[.9] dark:hue-rotate-180" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2518.4708244921435!2d20.98666698264619!3d52.12334869539107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTLCsDA3JzI0LjAiTiAyMMKwNTknMjEuNCJF!5e0!3m2!1sen!2spl!4v1717932177828!5m2!1sen!2spl" loading="lazy" />
            </div>
        </div>
    );
};

export default HomeLocation;
