import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='flex flex-col justify-center'>
            <hr className='w-full border-t border-card mt-16 mb-4' />

            <span id='about-us' className='text-3xl text-primary text-center my-4'>
                Dowiedz się więcej
            </span>

            <div className='grid xl:grid-cols-5 lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 py-4 text-start lg:px-32 md:px-16 sm:px-8 px-4 text-sm'>
                <div className='flex flex-col'>
                    <div className='flex flex-row gap-4 justify-start items-center text-primary font-bold'>Adres:</div>

                    <div className='flex flex-row gap-4 justify-start items-center'>Baletowa 113 C</div>

                    <div className='flex flex-row gap-4 justify-start items-center'>02-867 Warszawa</div>
                </div>

                <div className='flex flex-col'>
                    <div className='flex flex-row gap-4 justify-start items-center text-primary font-bold'>GPS:</div>

                    <div className='flex flex-row gap-4 justify-start items-center'>52°07'24.0"N</div>

                    <div className='flex flex-row gap-4 justify-start items-center'>20°59'21.4"E</div>
                </div>

                <div className='flex flex-col'>
                    <div className='flex flex-row gap-4 justify-start items-center text-primary font-bold'>Kontakt:</div>

                    <div className='flex flex-row gap-4 justify-start items-center'>+48 783 773 773</div>

                    <div className='flex flex-row gap-4 justify-start items-center'>royalparking.kontakt@gmail.com</div>
                </div>

                <div className='flex flex-col'>
                    <div className='flex flex-row gap-4 justify-start items-center text-primary font-bold'>Więcej:</div>

                    <div className='flex flex-row gap-4 justify-start items-center'>
                        <Link to='/legal/rules' className='underline underline-offset-2'>
                            Regulamin
                        </Link>
                    </div>

                    <div className='flex flex-row gap-4 justify-start items-center'>
                        <Link to='/legal/privacypolicy' className='underline underline-offset-2'>
                            Polityka prywatnośći
                        </Link>
                    </div>
                </div>

                <div className='flex flex-col'>
                    <div className='flex flex-row gap-4 justify-start items-center text-primary font-bold'>Realizacja:</div>

                    <div className='flex flex-row gap-4 justify-start items-center'>
                        <a href='https://bartczakdawid.com/' className='underline underline-offset-2'>
                            Dawid Bartczak
                        </a>
                    </div>

                    <div className='flex flex-row gap-4 justify-start items-center'>contact.bartczakdawid@gmail.com</div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
