import Card from '../../components/card';

interface HomeCardProps {
    time: string;
    price: string;
}

const HomePriceCard = ({ time, price }: HomeCardProps) => {
    return (
        <Card>
            <div className='flex flex-col items-center justify-start pb-2'>
                <span className='text-lg font-semibold'>{time}</span>
                <hr className='w-full rounded border-t border-foreground' />
                <span className='text-sm'>{price}</span>
            </div>
        </Card>
    );
};

export default HomePriceCard;
