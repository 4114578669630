import { useState, useEffect } from 'react';
import { ReactComponent as AngleLeftSolid } from '../../assets/icons/angle-left-solid.svg';
import { ReactComponent as AngleRightSolid } from '../../assets/icons/angle-right-solid.svg';
import images from '../../data/carousel.json';

const HomeCarousel = () => {
    const [index, setIndex] = useState(0);
    const [fadeIn, setFadeIn] = useState(false);

    const handlePrevious = () => {
        setFadeIn(false);
        setTimeout(() => {
            const newIndex = index - 1;
            setIndex(newIndex < 0 ? images.length - 1 : newIndex);
            setFadeIn(true);
        }, 600);
    };

    const handleNext = () => {
        setFadeIn(false);
        setTimeout(() => {
            const newIndex = index + 1;
            setIndex(newIndex >= images.length ? 0 : newIndex);
            setFadeIn(true);
        }, 600);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 10000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index]);

    return (
        <div className='w-full flex flex-row gap-4 justify-center items-center'>
            <div className='relative flex flex-row justify-center border-8 border-slate-900 rounded-lg bg-slate-900'>
                <img className={`lg:max-w-screen-lg object-cover rounded-lg transition-opacity duration-500 ${fadeIn ? 'opacity-100' : 'opacity-0'}`} src={require(`../../${images[index].src}`)} alt={images[index].alt} onLoad={() => setFadeIn(true)} />

                <div className='absolute inset-0 bg-black opacity-60 rounded-lg'></div>

                <div className='absolute inset-0 flex items-center justify-between'>
                    <button className='md:m-4 m-2 md:p-3 p-1 rounded-full bg-white opacity-80' onClick={handlePrevious}>
                        <AngleLeftSolid className='md:h-5 h-4 md:w-5 w-4 fill-black' />
                    </button>

                    <h2 className={`text-white md:text-2xl font-bold transition-opacity duration-500 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>{images[index].description}</h2>

                    <button className='md:m-4 m-2 md:p-3 p-1 rounded-full bg-white opacity-80' onClick={handleNext}>
                    <AngleRightSolid className='md:h-5 h-4 md:w-5 w-4 fill-black' />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HomeCarousel;
