import { useState, useEffect, useRef, useCallback } from "react";
import images from "../../data/gallery.json";

const HomeGallery = () => {
    const [index, setIndex] = useState(0);
    const [fadeIn, setFadeIn] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const galleryRef = useRef<HTMLDivElement | null>(null);

    const handleNext = useCallback(() => {
        setFadeIn(false);
        setTimeout(() => {
            const newIndex = index + 1;
            setIndex(newIndex >= images.length ? 0 : newIndex);
            setFadeIn(true);
        }, 600);
    }, [index]);

    const handleSetCustom = (target: number) => {
        if (target === index) {
            return;
        }
        setFadeIn(false);
        setTimeout(() => {
            const newIndex = target;
            setIndex(newIndex >= images.length ? 0 : newIndex);
            setFadeIn(true);
        }, 600);
    };

    const handleFullScreen = () => {
        console.log("handleFullScreen");
        if (!isFullScreen && galleryRef.current) {
            galleryRef.current.requestFullscreen();
            setIsFullScreen(true);
        }
    };

    const handleExitFullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
            setIsFullScreen(false);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isFullScreen) {
                handleNext();
            }
        }, 10000);

        return () => clearInterval(interval);
    }, [handleNext, index, isFullScreen]);

    useEffect(() => {
        const onFullScreenChange = () => {
            if (!document.fullscreenElement) {
                setIsFullScreen(false);
            }
        };

        document.addEventListener("fullscreenchange", onFullScreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", onFullScreenChange);
        };
    }, []);

    return (
        <div className="w-full flex flex-col justify-center py-8">
            <span id="gallery" className="text-3xl text-primary mt-16 mb-4">
                Galeria
            </span>

            <div className="w-full flex flex-row gap-4 justify-center items-center">
                <div ref={galleryRef} className={`w-screen-lg relative flex flex-col justify-center border-8 border-slate-900 rounded-lg bg-slate-900 ${isFullScreen ? "fullscreen-mode overflow-scroll" : ""}`}>
                    {isFullScreen && (
                        <button className={`absolute top-2 right-2 bg-white p-2 rounded-lg z-20 transition-opacity duration-500 ${fadeIn ? "opacity-80" : "opacity-0"}`} onClick={handleExitFullScreen}>
                            Exit Fullscreen
                        </button>
                    )}

                    {/* <div className="absolute inset-0 flex flex-row items-center justify-between">
                        <button className="md:m-4 m-2 md:p-3 p-1 rounded-full bg-white opacity-80" onClick={handlePrevious}>
                            <AngleLeftSolid className="md:h-5 h-4 md:w-5 w-4 fill-black" />
                        </button>

                        <button className="md:m-4 m-2 md:p-3 p-1 rounded-full bg-white opacity-80" onClick={handleNext}>
                            <AngleRightSolid className="md:h-5 h-4 md:w-5 w-4 fill-black" />
                        </button>
                    </div> */}

                    <img onClick={handleFullScreen} className={`z-10 mx-1 mt-2 ${!isFullScreen ? 'h-96' : 'my-2'} object-cover rounded-lg transition-opacity duration-500 ${fadeIn ? "opacity-100" : "opacity-0"}`} src={require(`../../${images[index].src}`)} alt={images[index].alt} onLoad={() => setFadeIn(true)} />

                    <div className="grid grid-cols-9 justify-evenly z-10 mb-2">
                        {images.map((image, imageIndex) => (
                            <div key={imageIndex} className="px-1 pt-2" onClick={() => handleSetCustom(imageIndex)}>
                                <img className="rounded-lg" src={require(`../../${image.src}`)} alt={image.alt} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeGallery;
