import { useMemo, useState } from 'react';
import Input from '../../../components/input';
import TextArea from '../../../components/textarea';
import { CreateReservation } from '../../../services/reservations';
import { CreateReservationDto } from '../../../dtos/CreateReservationDto';
import { regex } from '../../../services/configuration';
import Button from '../../../components/button';

const ReservationCreator = ({ handleClose }: { handleClose: Function }) => {
    const GetCurrentDate = () => {
        return new Date().toISOString().split('T')[0];
    };

    const [startDate, setStartDate] = useState<string>(GetCurrentDate());
    const [startTime, setStartTime] = useState<string>('12:00');
    const [endDate, setEndDate] = useState<string>(GetCurrentDate());
    const [endTime, setEndTime] = useState<string>('12:00');
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [amount, setAmount] = useState<number>(1);
    const [plate, setPlate] = useState<string>('');
    const [brand, setBrand] = useState<string>('');
    const [comment, setComment] = useState<string>('');

    const [error, setError] = useState('');

    useMemo(() => {
        setError('');

        if (!regex.phone.test(phone) || phone.length < 9 || phone.length > 12) {
            setError('Wprowadzono błędny numer telefonu');
        }

        if (!regex.email.test(email)) {
            setError('Wprowadzono błędny email');
        }

        if (!name || !email || !phone || amount < 1) {
            setError('Uzupełnij wszystkie wymagane pola');
        }
    }, [name, email, phone, amount]);

    const handleReservationCreate = async () => {
        const reservation: CreateReservationDto = {
            payment: 'Dodane przez admina',
            name: name,
            email: email,
            phone: phone,
            plate: plate,
            brand: brand,
            comment: comment,
            amount: amount,
            start: `${startDate}T${startTime}`,
            end: `${endDate}T${endTime}`,
        };

        await CreateReservation(reservation);

        close();
    };

    const close = () => {
        handleClose();
    };

    return (
        <div className='fixed inset-0 z-50 backdrop-blur-sm flex justify-center overflow-scroll p-8'>
            <div className='bg-card h-fit p-4 border border-primary-text dark:border-transparent rounded-lg sm:max-w-screen-sm w-full flex flex-col'>
                <div className='flex flex-col m-4 text-start'>
                    {error && (
                        <>
                            <span className='text-red-500'>*{error}</span>

                            <hr className='w-full my-4 border-t' />
                        </>
                    )}

                    <div className='flex flex-col md:flex-row w-full gap-2 p-2 items-end'>
                        <div className='flex flex-col gap-2 w-full'>
                            <span>
                                Data przyjazdu <span className='text-red-500'>*</span>
                            </span>
                            <Input type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)} min={GetCurrentDate()} />
                        </div>
                        <div className='flex flex-col gap-2 w-full'>
                            <span>
                                Godzina przyjazdu (na parking) <span className='text-red-500'>*</span>
                            </span>
                            <Input type='time' value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row w-full gap-2 p-2 items-end'>
                        <div className='flex flex-col gap-2 w-full'>
                            <span>
                                Data wyjazdu <span className='text-red-500'>*</span>
                            </span>
                            <Input type='date' value={endDate} onChange={(e) => setEndDate(e.target.value)} min={startDate} />
                        </div>
                        <div className='flex flex-col gap-2 w-full'>
                            <span>
                                Godzina wyjazdu (przylotu) <span className='text-red-500'>*</span>
                            </span>
                            <Input type='time' value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                        </div>
                    </div>

                    <div className='flex flex-col gap-2 p-2 w-full'>
                        <span>
                            Imie i Nazwisko <span className='text-red-500'>*</span>
                        </span>
                        <Input placeholder='Twoje imię i nazwisko' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>

                    <div className='flex flex-col gap-2 p-2 w-full'>
                        <span>
                            Email <span className='text-red-500'>*</span>
                        </span>
                        <Input placeholder='Twój email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>

                    <div className='flex flex-col gap-2 p-2 w-full'>
                        <span>
                            Nr. Telefonu <span className='text-red-500'>*</span>
                        </span>
                        <Input placeholder='Twój numer telefonu' value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>

                    <div className='flex flex-col gap-2 p-2 w-full'>
                        <span>
                            Liczba osób <span className='text-red-500'>*</span>
                        </span>
                        <Input type='number' placeholder='Liczba osób' value={amount} onChange={(e) => setAmount(+e.target.value)} />
                    </div>

                    <div className='flex flex-col gap-2 p-2 w-full'>
                        <span>Nr. Rejestracyjny</span>
                        <Input placeholder='Nr. rejestracyjny twojego samochodu' value={plate} onChange={(e) => setPlate(e.target.value)} />
                    </div>

                    <div className='flex flex-col gap-2 p-2 w-full'>
                        <span>Marka pojazdu</span>
                        <Input placeholder='Marka twojego samochodu' value={brand} onChange={(e) => setBrand(e.target.value)} />
                    </div>

                    <div className='flex flex-col gap-2 p-2 w-full'>
                        <span>Komentarz</span>
                        <TextArea className='min-h-16' placeholder='Detale odnośnie zamówienia' value={comment} onChange={(e) => setComment(e.target.value)} />
                    </div>

                    <hr className='w-full my-4 border-t' />

                    {!error && (
                        <div className='flex flex-col gap-4 justify-between items-center'>
                            <Button className='w-full m-2' onClick={handleReservationCreate}>
                                Utwórz
                            </Button>
                        </div>
                    )}

                    <Button className='w-full' variant='destructive' onClick={close}>
                        Anuluj
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ReservationCreator;
