import { useEffect } from "react";
import ReactGA from "react-ga4";

const PrivacyPolicy = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/legal/privacypolicy",
            title: "Privacy Policy",
        });
    }, []);

    return (
        <div className="text-start flex flex-col gap-4 justify-start">
            <h1 className="text-3xl text-center p-8 text-primary font-bold">Polityka prywatności</h1>

            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">1. Postanowienia ogólne</h2>
                <p>Polityka prywatności określa, jak zbierane, przetwarzane i przechowywane są dane osobowe Użytkowników niezbędne do świadczenia usług drogą elektroniczną za pośrednictwem serwisu internetowego www.royalparking.pl (dalej: Serwis).</p>
                <p>Serwis zbiera wyłącznie dane osobowe niezbędne do świadczenia i rozwoju usług w nim oferowanych.</p>
                <p>Dane osobowe zbierane za pośrednictwem Serwisu są przetwarzane zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych, dalej RODO) oraz ustawą o ochronie danych osobowych z dnia 10 maja 2018 r.</p>
            </section>

            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">2. Administrator danych</h2>
                <p>Administratorem danych osobowych zbieranych poprzez Serwis jest Royal Parking – Tomasz Szymczyk adres: Baletowa 113C , NIP: 1230203368, REGON: 017443106, adres poczty elektronicznej: Royalparking.kontakt@gmail.com, dalej: Administrator).</p>
            </section>

            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">3. Cel zbierania danych osobowych</h2>
                <p>Dane osobowe wykorzystywane są w celu:</p>
                <ul>
                    <li> - rejestracji konta i weryfikacji tożsamości Użytkownika,</li>
                    <li> - umożliwienia logowania do Serwisu,</li>
                    <li> - realizacji umowy dotyczącej usług i e-usług,</li>
                    <li> - komunikacji z Użytkownikiem (livechat, formularz kontaktowy itp.),</li>
                    <li> - wysyłki newslettera (po wyrażeniu zgody Użytkownika na jego otrzymywanie),</li>
                    <li> - prowadzenia systemu komentarzy,</li>
                    <li> - świadczenia usług społecznościowych,</li>
                    <li> - promocji oferty Administratora,</li>
                    <li> - marketingu, remarketingu, afiliacji,</li>
                    <li> - personalizacji Serwisu dla Użytkowników,</li>
                    <li> - działań analitycznych i statystycznych,</li>
                    <li> - windykacji należności,</li>
                    <li> - ustalenia i dochodzenia roszczeń albo obrony przed nimi.</li>
                </ul>
                <p>Podanie danych jest dobrowolne, ale niezbędne do zawarcia umowy albo skorzystania z innych funkcjonalności Serwisu.</p>
            </section>

            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">4. Rodzaj przetwarzanych danych osobowych</h2>
                <p>Administrator może przetwarzać dane osobowe Użytkownika: imię i nazwisko, data urodzenia, adres zamieszkania, adres e-mail, numer telefonu, NIP.</p>
            </section>

            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">5. Okres przetwarzania danych osobowych</h2>
                <p>Dane osobowe Użytkowników będą przetwarzane przez okres:</p>
                <ul>
                    <li> - gdy podstawą przetwarzania danych jest wykonanie umowy – do momentu przedawnienia roszczeń po jej wykonaniu,</li>
                    <li> - gdy podstawą przetwarzania danych jest zgoda – do momentu jej odwołania, a po odwołaniu zgody do przedawnienia roszczeń.</li>
                </ul>
                <p>W obu przypadkach termin przedawnienia wynosi 6 lat, a dla roszczeń o świadczenia okresowe i roszczeń dotyczących prowadzenia działalności gospodarczej – 3 lata (jeśli przepis szczególny nie stanowi inaczej).</p>
            </section>

            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">6. Udostępnianie danych osobowych</h2>
                <p>Dane osobowe Użytkowników mogą być przekazywane:</p>
                <ul>
                    <li> - podmiotom powiązanym z Administratorem,</li>
                    <li> - jego podwykonawcom,</li>
                    <li> - podmiotom współpracującym z Administratorem np. firmom obsługującym e-płatności, firmom świadczącym usługi kurierskie/pocztowe, kancelariom prawnym.</li>
                </ul>
                <p>Dane osobowe Użytkowników nie będą przekazywane poza teren Europejskiego Obszaru Gospodarczego (EOG).</p>
            </section>

            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">7. Prawa Użytkowników</h2>
                <p>Użytkownik Serwisu ma prawo do:</p>
                <ul>
                    <li> - dostępu do treści swoich danych osobowych,</li>
                    <li> - ich sprostowania,</li>
                    <li> - usunięcia,</li>
                    <li> - ograniczenia przetwarzania,</li>
                    <li> - przenoszenia,</li>
                    <li> - wniesienia sprzeciwu wobec przetwarzania,</li>
                    <li> - cofnięcia zgody w każdej chwili (co nie ma wpływu na zgodność z prawem przetwarzania dokonanego w oparciu o zgodę przed jej cofnięciem).</li>
                </ul>
                <p>Zgłoszenie o wystąpieniu przez Użytkownika z uprawnieniem wynikającym z wymienionych praw należy przesłać na adres: royalparking.kontakt@gmail.com</p>
                <p>Administrator spełnia lub odmawia spełnienia żądania niezwłocznie – maksymalnie w ciągu miesiąca od jego otrzymania.</p>
                <p>Użytkownik ma prawo złożyć skargę do Prezesa Urzędu Ochrony Danych Osobowych, jeśli uzna, że przetwarzanie narusza jego prawa i wolności (RODO).</p>
            </section>

            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">8. Pliki cookies</h2>
                <p>Serwis zbiera informacje za pomocą plików cookies – sesyjnych, stałych i podmiotów zewnętrznych.</p>
                <p>Zbieranie plików cookies wspiera poprawne świadczenie usług w Serwisie i służy celom statystycznym.</p>
                <p>Użytkownik może określić zakres dostępu plików cookies do swojego urządzenia w ustawieniach przeglądarki.</p>
            </section>

            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">9. Zautomatyzowane podejmowanie decyzji i profilowanie</h2>
                <p>Dane Użytkowników nie mogą być przetwarzane w zautomatyzowany sposób tak, że na skutek tego mogłyby zapaść wobec nich jakiekolwiek decyzje.</p>
                <p>Dane Użytkowników mogą być profilowane celem dostosowania treści i personalizacji oferty po wyrażeniu przez nich zgody.</p>
            </section>

            <section className="flex flex-col gap-2">
                <h2 className="text-2xl mt-8 mb-4 font-semibold">10. Postanowienia końcowe</h2>
                <p>Administrator ma prawo do wprowadzenia zmian w Polityce prywatności, przy czym prawa Użytkowników nie zostaną ograniczone.</p>
                <p>Informacja o wprowadzonych zmianach pojawi się w formie komunikatu dostępnego w Serwisie.</p>
                <p>W sprawach nieuregulowanych w niniejszej Polityce prywatności obowiązują przepisy RODO i przepisy prawa polskiego.</p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
