import { Link } from "react-router-dom";
import logo from "../../../assets/logo/logo.png";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const ReservationComplete = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/reservations/completed",
            title: "Reservation Completed",
        });
    }, []);

    return (
        <div className="h-screen w-full flex flex-col justify-start items-center content-center mt-16">
            <Link to="/">
                <img className="h-36 p-2" src={logo} alt="logo" />
            </Link>
            <span id="about-us" className="lg:text-4xl text-2xl text-secondary mt-8 font-semibold">
                Zakończono proces rezerwacji.
            </span>
            <span id="about-us" className="lg:text-lg text-md">
                Jeżeli płaciłeś online, sprawdź swój adres email w celu weryfikacji, czy wszystko przebiegło pomyślnie.
            </span>
        </div>
    );
};

export default ReservationComplete;
