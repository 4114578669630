type ButtonVariant = 'primary' | 'outline' | 'destructive' | 'clean';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: ButtonVariant;
}

const Button = ({ variant = 'primary', className, ...props }: ButtonProps) => {
    let variants = {
        primary: 'bg-primary text-primary-foreground hover:bg-secondary',
        outline: 'bg-card text-primary border border-primary-text dark:border-transparent hover:bg-background',
        destructive: 'bg-red-500 text-primary-foreground hover:bg-red-600',
        clean: 'bg-card text-primary dark:border-transparent hover:bg-background'
    };

    return <button {...props} className={`rounded-md w-full h-min p-2 font-semibold ${variants[variant]} ${className}`} />;
};

export default Button;
