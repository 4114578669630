import { useEffect, useMemo, useState } from "react";
import Button from "../../../components/button";
import Card from "../../../components/card";
import { useAuth } from "../../../context/useAuth";
import { Link, useNavigate } from "react-router-dom";
import { DeleteUser, GetAllUsers, UpdateUserPassword } from "../../../services/authentication";
import { ReactComponent as UserSolid } from "../../../assets/icons/user-solid.svg";
import { AppUser } from "../../../models/AppUser";
import ReactGA from "react-ga4";

const AccountManage = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/account/manage",
            title: "Account Manage",
        });
    }, []);

    const navigate = useNavigate();

    const { authUser, isLoggedIn } = useAuth();

    const [thisUser, setThisUser] = useState<AppUser>();
    const [users, setUsers] = useState<AppUser[]>([]);

    const handleUserDelete = async (username: string) => {
        if (thisUser?.role !== "Root") {
            return;
        }

        if (!window.confirm(`Czy na pewno chcesz usunąć konto ${username}?`)) {
            return;
        }

        await DeleteUser(username);

        let _users = await GetAllUsers();

        setUsers(_users ?? []);
    };

    const handleUserPasswordUpdate = (username: string) => {
        if (thisUser?.role !== "Root") {
            return;
        }

        let password = window.prompt(`Podaj nowe hasło dla ${username}`);

        if (!password) {
            return;
        }

        if (!window.confirm(`Czy na pewno chcesz zmienić hasło ${username} na ${password}?`)) {
            return;
        }

        UpdateUserPassword(username, password);
    };

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/account/login");
            return;
        }

        const fetchUsers = async () => {
            let _users = await GetAllUsers();

            setUsers(_users ?? []);
        };

        fetchUsers();
    }, [isLoggedIn, navigate]);

    useMemo(() => {
        let _thisUser = users.find((user) => user.username === authUser?.userName);

        setThisUser(_thisUser);
    }, [users, authUser]);

    return (
        <div className="flex flex-col justify-center items-center w-full text-start gap-4">
            <div className="flex flex-row w-full justify-between">
                <Link to="/reservations/manage">
                    <Button>Wróć</Button>
                </Link>

                {thisUser?.role === "Root" && (
                    <Link to="/account/create">
                        <Button>Dodaj użytkownika</Button>
                    </Link>
                )}
            </div>
            <Card className="w-full">
                {users.map((user, index) => (
                    <div key={user.username}>
                        {index !== 0 && <hr className="w-full my-2 border-t" />}

                        <div className="flex flex-row justify-between items-center">
                            <div className={`flex flex-row gap-4 items-center ${user?.role === "Root" && "fill-primary"}`}>
                                <UserSolid className="w-6 h-6" /> {user.username.toUpperCase()}
                            </div>

                            {thisUser?.role === "Root" && (
                                <div className="flex flex-row gap-2 items-center">
                                    <Button
                                        className="w-fit text-nowrap"
                                        onClick={() => {
                                            handleUserPasswordUpdate(user.username);
                                        }}
                                    >
                                        Zmień hasło
                                    </Button>
                                    <Button
                                        className="w-fit"
                                        onClick={() => {
                                            handleUserDelete(user.username);
                                        }}
                                        variant="destructive"
                                    >
                                        Usuń
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </Card>
        </div>
    );
};

export default AccountManage;
