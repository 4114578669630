import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/button";
import Card from "../../../components/card";
import { useEffect, useMemo, useState } from "react";
import Input from "../../../components/input";
import TextArea from "../../../components/textarea";
import { CreateReservation, GetPriceForDateRange } from "../../../services/reservations";
import { config, regex } from "../../../services/configuration";
import { CreateReservationDto } from "../../../dtos/CreateReservationDto";
import { RegisterTransaction } from "../../../services/transactions";
import ReactGA from "react-ga4";

const ReservationForm = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/reservations/form",
            title: "Reservation Form",
        });
    }, []);

    const navigate = useNavigate();

    const GetCurrentDate = () => {
        return new Date().toISOString().split("T")[0];
    };

    const [price, setPrice] = useState<number>(1);
    const [error, setError] = useState<string | undefined>();

    const [startDate, setStartDate] = useState<string>(GetCurrentDate());
    const [startTime, setStartTime] = useState<string>("12:00");
    const [endDate, setEndDate] = useState<string>(GetCurrentDate());
    const [endTime, setEndTime] = useState<string>("12:00");
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [amount, setAmount] = useState<number>(1);
    const [plate, setPlate] = useState<string>("");
    const [brand, setBrand] = useState<string>("");
    const [comment, setComment] = useState<string>("");

    const [payCash, setPayCash] = useState<boolean>(false);
    const [acceptRules, setAcceptRules] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    useMemo(() => {
        const fetchPrice = async () => {
            const result = await GetPriceForDateRange(new Date(startDate), new Date(endDate));

            setPrice(result);
        };

        fetchPrice();
    }, [startDate, endDate]);

    useMemo(() => {
        setError("");

        if (!acceptRules) {
            setError("Zaakceptuj regulamin");
        }

        if (!price) {
            setError("Wybrany okres jest niedostepny");
        }

        if (!regex.phone.test(phone) || phone.length < 9 || phone.length > 12) {
            setError("Wprowadzono błędny numer telefonu");
        }

        if (!regex.email.test(email)) {
            setError("Wprowadzono błędny email");
        }

        if (!name || !email || !phone || amount < 1) {
            setError("Uzupełnij wszystkie wymagane pola");
        }
    }, [price, name, email, phone, amount, acceptRules]);

    const HandleReservationCreate = async () => {
        setLoading(true);

        ReactGA.event({
            category: "click",
            action: `reservation_form_submit_${payCash ? "cash" : "online"}`,
            label: `Submit reservation form (Pay cash : ${payCash})`,
        });

        const reservation: CreateReservationDto = {
            payment: payCash ? "Gotowka" : "Oczekuje",
            name: name,
            email: email,
            phone: phone,
            plate: plate,
            brand: brand,
            comment: comment,
            amount: amount,
            start: `${startDate}T${startTime}`,
            end: `${endDate}T${endTime}`,
        };

        if (payCash) {
            const result = await CreateReservation(reservation);

            if (!result) {
                return;
            }

            navigate("/reservations/success");
        } else {
            const token = await RegisterTransaction(reservation);

            if (!token) {
                return;
            }

            window.location.href = `${config.przelewy24}trnRequest/${token}`;
        }

        setLoading(false);
    };

    return (
        <div className="w-full flex flex-col justify-center py-8">
            <span className="text-3xl text-primary">Rezerwacja</span>

            <span className="text-gray-500 mb-4">
                <span className="text-foreground text-lg">Formularz</span> {">"} Płatność {">"} Potwierdzenie
            </span>

            <div className="md:max-w-screen-md w-full flex flex-col gap-2 justify-center mx-auto">
                <Link to="/" className="w-full flex justify-start">
                    <Button className="w-min">Wróć</Button>
                </Link>

                <Card>
                    <div className="flex flex-col m-4 text-start">
                        {error && (
                            <>
                                <span className="text-red-500">*{error}</span>

                                <hr className="w-full my-4 border-t" />
                            </>
                        )}

                        <div className="flex flex-col md:flex-row w-full gap-2 p-2 items-end">
                            <div className="flex flex-col gap-2 w-full">
                                <span>
                                    Data przyjazdu <span className="text-red-500">*</span>
                                </span>
                                <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} min={GetCurrentDate()} />
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                                <span>
                                    Godzina przyjazdu (na parking) <span className="text-red-500">*</span>
                                </span>
                                <Input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row w-full gap-2 p-2 items-end">
                            <div className="flex flex-col gap-2 w-full">
                                <span>
                                    Data wyjazdu <span className="text-red-500">*</span>
                                </span>
                                <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} min={startDate} />
                            </div>
                            <div className="flex flex-col gap-2 w-full">
                                <span>
                                    Godzina wyjazdu (przylotu) <span className="text-red-500">*</span>
                                </span>
                                <Input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 p-2 w-full">
                            <span>
                                Imie i Nazwisko <span className="text-red-500">*</span>
                            </span>
                            <Input placeholder="Twoje imię i nazwisko" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>

                        <div className="flex flex-col gap-2 p-2 w-full">
                            <span>
                                Email <span className="text-red-500">*</span>
                            </span>
                            <Input placeholder="Twój email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>

                        <div className="flex flex-col gap-2 p-2 w-full">
                            <span>
                                Nr. Telefonu <span className="text-red-500">*</span>
                            </span>
                            <Input placeholder="Twój numer telefonu" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>

                        <div className="flex flex-col gap-2 p-2 w-full">
                            <span>
                                Liczba osób <span className="text-red-500">*</span>
                            </span>
                            <Input type="number" placeholder="Liczba osób" value={amount} onChange={(e) => setAmount(+e.target.value)} />
                        </div>

                        <div className="flex flex-col gap-2 p-2 w-full">
                            <span>Nr. Rejestracyjny</span>
                            <Input placeholder="Nr. rejestracyjny twojego samochodu" value={plate} onChange={(e) => setPlate(e.target.value)} />
                        </div>

                        <div className="flex flex-col gap-2 p-2 w-full">
                            <span>Marka pojazdu</span>
                            <Input placeholder="Marka twojego samochodu" value={brand} onChange={(e) => setBrand(e.target.value)} />
                        </div>

                        <div className="flex flex-col gap-2 p-2 w-full">
                            <span>Komentarz</span>
                            <TextArea className="min-h-16" placeholder="Detale odnośnie zamówienia" value={comment} onChange={(e) => setComment(e.target.value)} />
                        </div>

                        <div className="w-full p-2 flex gap-2 items-center">
                            <Input className="w-min" type="checkbox" checked={acceptRules} onChange={(e) => setAcceptRules(!acceptRules)} />
                            <span id="terms-span" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Przeczytałem i akceptuję{" "}
                                <Link to="/legal/rules" className="underline hover:text-primary">
                                    Regulamin
                                </Link>{" "}
                                <span className="text-red-500">*</span>
                            </span>
                        </div>

                        <div className="w-full p-2 flex gap-2 items-center">
                            <Input className="w-min" type="checkbox" checked={payCash} onChange={(e) => setPayCash(!payCash)} />
                            <span id="cash-span" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Chcę zapłacić gotówką na miejscu
                            </span>
                        </div>

                        {!error && (
                            <>
                                <hr className="w-full my-4 border-t" />

                                <div className="flex flex-col gap-4 justify-between items-center">
                                    <span className="py-1">Cena: {price} zł</span>
                                    <Button className="w-full" disabled={loading} onClick={HandleReservationCreate}>
                                        Przejdź dalej
                                    </Button>
                                </div>
                            </>
                        )}

                        <hr className="w-full my-4 border-t" />

                        <span className="text-xs">Jeżeli chcesz otrzymać fakturę VAT, najpierw skontaktuj się z nami telefonicznie - 783 773 773</span>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default ReservationForm;
