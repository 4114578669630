import React, { ReactNode } from 'react';
import Card from './card';

interface DropdownProps {
    open: boolean;
    trigger: ReactNode;
    menu: ReactNode[];
}

const Dropdown: React.FC<DropdownProps> = ({ open, trigger, menu }) => {
    return (
        <div className='w-min text-nowrap text-start'>
            {trigger}
            {open && (
                <Card className='absolute w-min h-min mt-1'>
                    <ul>
                        {menu.map((menuItem, index) => (
                            <div key={index}>
                                {index !== 0 && <hr className='w-full my-1 border-t' />}
                                <li>
                                    {menuItem}
                                </li>
                            </div>
                        ))}
                    </ul>
                </Card>
            )}
        </div>
    );
};

export default Dropdown;
