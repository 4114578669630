import { useEffect } from "react";
import Button from "../../../components/button";
import { useAuth } from "../../../context/useAuth";
import { Link, useNavigate } from "react-router-dom";
import ReservationList from "./ReservationList";
import ReactGA from "react-ga4";

const ReservationManager = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/reservations/manage",
            title: "Reservation Manager",
        });
    }, []);

    const navigate = useNavigate();

    const { logout, isLoggedIn } = useAuth();

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/account/login");
            return;
        }
    }, [isLoggedIn, navigate]);

    const handleLogout = () => {
        const confirmed = window.confirm(`Czy na pewno chcesz się wylogować?`);

        if (!confirmed) {
            return;
        }

        logout();
    };

    return (
        <div className="flex flex-col justify-center items-center w-full text-start gap-4">
            <div className="flex flex-row w-full justify-between">
                <Link to="/reservations/manage">
                    <Button>Wróć</Button>
                </Link>
                <div className="flex flex-row gap-1">
                    <Link to="/account/manage">
                        <Button>Użytkownicy</Button>
                    </Link>
                    <Button variant="destructive" onClick={handleLogout}>
                        Wyloguj się
                    </Button>
                </div>
            </div>

            <ReservationList />
        </div>
    );
};

export default ReservationManager;
