import { Link } from 'react-router-dom';
import Button from '../../components/button';
import Card from '../../components/card';

const HomeActions = () => {
    return (
        <div className='w-full flex flex-col justify-center py-8'>
            <span id='about-us' className='text-3xl text-primary mt-16 mb-4'>
                Zarezerwuj parking
            </span>

            <div className='flex lg:flex-row flex-col flex-wrap gap-5 justify-center py-4'>
                <Card className='bg-primary lg:max-w-96 w-full'>
                    <div className='flex flex-col gap-2 w-full'>
                        <span className='text-2xl text-start text-white font-semibold w-full'>Online</span>
                        <Link className="w-[40%]" to='reservations/form'>
                            <Button className=' bg-white text-slate-900 hover:text-white'>Zarezerwuj</Button>
                        </Link>
                    </div>
                </Card>

                <Card className='bg-slate-900 lg:max-w-96 w-full'>
                    <div className='flex flex-col gap-2 w-full'>
                        <span className='text-2xl text-start text-white font-semibold w-full'>Telefonicznie: 783 773 773</span>
                        <a className='w-[40%]' href='tel:783773773'>
                            <Button className='hover:text-white'>Zadzwoń</Button>
                        </a>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default HomeActions;
