import { useEffect, useState } from 'react';
import Card from '../../../components/card';
import Dropdown from '../../../components/dropdown';
import Button from '../../../components/button';
import { GetReservationQuery, ReservationType } from '../../../models/ReservationType';
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevron-down-solid.svg';
import { ReactComponent as AngleRight } from '../../../assets/icons/angle-right-solid.svg';
import { ReactComponent as AngleLeft } from '../../../assets/icons/angle-left-solid.svg';
import { ReactComponent as PenSolid } from '../../../assets/icons/pen-solid.svg';
import { ReactComponent as FolderPlusSolid } from '../../../assets/icons/folder-plus-solid.svg';
import { GetReservations } from '../../../services/reservations';
import { Reservation } from '../../../models/Reservation';
import ReservationCreator from './ReservationCreator';
import ReservationEditor from './ReservationEditor';

const ReservationList = () => {
    const [showCreator, setShowCreator] = useState(false);
    const [showEditor, setShowEditor] = useState(false);

    const [dropdown, setDropdown] = useState(false);
    const [reservationType, setReservationType] = useState<ReservationType>('today');
    const [page, setPage] = useState(1);

    const [reservations, setReservations] = useState<Reservation[]>([]);
    const [reservation, setReservation] = useState<Reservation>();

    enum labels {
        parked = 'Na placu',
        today = 'Dzisiejsze',
        future = 'Przyszłe',
        past = 'Zakończone',
        confirmed = 'Potwierdzone',
        unconfirmed = 'Wymagające potwierdzenia',
        archived = 'Archiwum',
        all = 'Wszystkie',
    }

    const toggleDropdown = () => {
        setDropdown(!dropdown);
    };

    const fetchReservations = async (page: number, type: ReservationType) => {
        const query: GetReservationQuery = {
            type: type,
            sortBy: 'start_date',
            isDecsending: false,
            page: true,
            pageNumber: page,
            pageSize: 20,
        };

        return await GetReservations(query);
    };

    const handleReservationTypeChange = async (type: ReservationType) => {
        setReservationType(type);
        setDropdown(false);
        setPage(1);

        const newReservations = await fetchReservations(1, type);

        setReservations(newReservations);
    };

    const handlePageSet = async (newPage: number) => {
        const newReservations = await fetchReservations(newPage, reservationType);

        if (newReservations.length < 1) {
            return;
        }

        setPage(Math.max(newPage, 1));
        setReservations(newReservations);
    };

    const handleReservationEdit = (_reservation: Reservation) => {
        setReservation(_reservation);
        setShowEditor(true);
    };

    useEffect(() => {
        fetchReservations(1, reservationType).then((result) => {
            setReservations(result);
        });
    }, [reservationType]);

    return (
        <>
            {showCreator && <ReservationCreator handleClose={() => setShowCreator(false)} />}
            {showEditor && (
                <ReservationEditor
                    handleClose={() => setShowEditor(false)}
                    reservation={reservation}
                />
            )}
            <div className="flex flex-row w-full justify-between">
                <Card>
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-row items-center gap-2">
                            <Button
                                variant="outline"
                                className="w-min"
                                onClick={() => setShowCreator(true)}
                            >
                                <FolderPlusSolid className="w-4 h-4 fill-primary" />
                            </Button>
                            <Dropdown
                                open={dropdown}
                                trigger={
                                    <Button
                                        className="flex flex-row items-center gap-2"
                                        variant="outline"
                                        onClick={toggleDropdown}
                                    >
                                        {labels[reservationType]} <ChevronDown className="w-4 h-4" />
                                    </Button>
                                }
                                menu={[
                                    <Button
                                        className="text-start"
                                        variant="clean"
                                        onClick={() => handleReservationTypeChange('parked')}
                                    >
                                        Na placu
                                    </Button>,
                                    <Button
                                        className="text-start"
                                        variant="clean"
                                        onClick={() => handleReservationTypeChange('today')}
                                    >
                                        Dzisiaj
                                    </Button>,
                                    <Button
                                        className="text-start"
                                        variant="clean"
                                        onClick={() => handleReservationTypeChange('future')}
                                    >
                                        Przyszłe
                                    </Button>,
                                    <Button
                                        className="text-start"
                                        variant="clean"
                                        onClick={() => handleReservationTypeChange('past')}
                                    >
                                        Zakończone
                                    </Button>,
                                    <Button
                                        className="text-start"
                                        variant="clean"
                                        onClick={() => handleReservationTypeChange('confirmed')}
                                    >
                                        Potwierdzone
                                    </Button>,
                                    <Button
                                        className="text-start"
                                        variant="clean"
                                        onClick={() => handleReservationTypeChange('unconfirmed')}
                                    >
                                        Wymagające potwierdzenia
                                    </Button>,
                                    <Button
                                        className="text-start"
                                        variant="clean"
                                        onClick={() => handleReservationTypeChange('archived')}
                                    >
                                        Archiwum
                                    </Button>,
                                    <Button
                                        className="text-start"
                                        variant="clean"
                                        onClick={() => handleReservationTypeChange('all')}
                                    >
                                        Wszystko
                                    </Button>,
                                ]}
                            />
                        </div>
                        <div className="flex flex-row items-center gap-2">
                            <Button
                                variant="clean"
                                onClick={() => handlePageSet(Math.max(1, page - 1))}
                            >
                                <AngleLeft className="w-4 h-4" />
                            </Button>
                            <span className="text-xl">{page}</span>
                            <Button
                                variant="clean"
                                onClick={() => handlePageSet(page + 1)}
                            >
                                <AngleRight className="w-4 h-4" />
                            </Button>
                        </div>
                    </div>

                    <div className="flex flex-col mt-4">
                        <div className="flex flex-row gap-1 w-full">
                            <Button
                                className="w-min px-0 invisible disabled"
                                variant="clean"
                            >
                                <PenSolid className="w-3 h-3 sm:mx-3 fill-yellow-500" />
                            </Button>
                            <div className="w-full grid lg:grid-cols-5 sm:grid-cols-4 grid-cols-3 items-center font-semibold">
                                <span>Przyjazd</span>
                                <span>Odjazd</span>
                                <span>Imię</span>
                                <span className="hidden sm:block">Ilość osób</span>
                                <span className="hidden lg:block">Rejestracja</span>
                            </div>
                        </div>
                        {reservations.map((reservation, index) => (
                            <div>
                                <hr className="w-full my-1 border-t" />
                                <div className="flex flex-row w-full gap-1">
                                    <Button
                                        className="w-min px-0"
                                        variant="clean"
                                        onClick={() => handleReservationEdit(reservation)}
                                    >
                                        <PenSolid className="w-3 h-3 sm:mx-3 fill-yellow-500" />
                                    </Button>
                                    <div className={`w-full grid lg:grid-cols-5 sm:grid-cols-4 grid-cols-3 items-center ${!reservation.active && 'line-through'}`}>
                                        <span key={index}>{reservation.start.toLocaleString().replace('T', ' ')}</span>
                                        <span>{reservation.end.toLocaleString().replace('T', ' ')}</span>
                                        <span>{reservation.name}</span>
                                        <span className='hidden sm:block'>{reservation.amount}</span>
                                        <span className="hidden sm:block">{reservation.plate === '' ? "Brak" : reservation.plate}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Card>
            </div>
        </>
    );
};

export default ReservationList;
