import HomeCard from './HomeCard';
import { ReactComponent as CalendarSolid } from '../../assets/icons/calendar-solid.svg';
import { ReactComponent as CarSideSolid } from '../../assets/icons/car-side-solid.svg';
import { ReactComponent as CartSuitcaseSolid } from '../../assets/icons/cart-flatbed-suitcase-solid.svg';
import { ReactComponent as FaceSmileSolid } from '../../assets/icons/face-smile-beam-solid.svg';

const HomeInstructions = () => {
    return (
        <div className='w-full flex flex-col justify-center py-8'>
            <span id='about-us' className='text-3xl text-primary mt-16 mb-4'>
                Dlaczego my?
            </span>

            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 justify-center py-4 text-start'>
                <HomeCard description='Wybierz termin dokonaj rezerwacji za pomocą strony internetowej, lub zadzwoń do nas' icon={CalendarSolid} />
                <HomeCard description='Przyjedź na nasz parking, a my dowieziemy cię szybko i bezpiecznie na lotnisko' icon={CarSideSolid} />
                <HomeCard description='Ciesz się beztroskim wypoczynkiem, wiedząc że twój samochód jest bezpieczny' icon={FaceSmileSolid} />
                <HomeCard description='Gdy wrócisz na lotnisko, odbierz swój bagaż i zadzwoń po nas, a my przyjedziemy po ciebie.' icon={CartSuitcaseSolid} />
            </div>
        </div>
    );
};

export default HomeInstructions;
