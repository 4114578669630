import { useEffect } from "react";
import HomeAbout from "./HomeAbout";
import HomeActions from "./HomeActions";
import HomeCarousel from "./HomeCarousel";
import HomeGallery from "./HomeGallery";
import HomeInstructions from "./HomeInstructions";
import HomeLocation from "./HomeLocation";
import HomePrice from "./HomePrice";
import HomePriceCalculator from "./HomePriceCalculator";
import ReactGA from "react-ga4";

const Home = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/",
            title: "Home",
        });
    }, []);

    return (
        <>
            <div id="home" className="flex flex-col justify-center py-8">
                <span className="text-4xl text-primary font-bold">Parking przy lotnisku Chopina Warszawa-Okęcie</span>
                <span className="text-3xl font-semibold">Promocyjne Ceny</span>
            </div>

            <HomeCarousel />

            <HomeAbout />

            <HomePrice />

            <HomePriceCalculator />

            <HomeInstructions />

            <HomeGallery />

            <HomeLocation />

            <HomeActions />
        </>
    );
};

export default Home;
