import { ToggleTheme } from '../services/theme';
import logo from '../assets/logo/logo.png';
import Button from '../components/button';
import { ReactComponent as SunRegular } from '../assets/icons/sun-regular.svg';
import { ReactComponent as MoonRegular } from '../assets/icons/moon-regular.svg';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const handleAnchorClick = (event: { preventDefault: () => void; currentTarget: any }) => {
        const link = event.currentTarget;
        const currentUrl = new URL(window.location.href);
        const targetUrl = new URL(link.href);

        if (currentUrl.pathname === targetUrl.pathname) {
            event.preventDefault();
            const anchorId = targetUrl.hash.replace('#', '');
            const anchor = document.getElementById(anchorId);

            if (!anchor) return;

            window.scrollTo({
                behavior: 'smooth',
                top: anchor.offsetTop - 200,
            });
        }
    };

    return (
        <div className='sticky top-0 w-full flex flex-row justify-between backdrop-blur-lg lg:px-32 md:px-16 sm:px-8 px-4 z-50'>
            <Link to='/#home' onClick={handleAnchorClick}>
                <img className='h-16 md:h-20 p-2' src={logo} alt='logo' />
            </Link>
            <div className='flex flex-row gap-4 items-center'>
                <Link className='text-foreground font-semibold hidden md:block hover:text-primary' to='/#about-us' onClick={handleAnchorClick}>
                    Dlaczego my?
                </Link>
                <Link className='text-foreground font-semibold hidden md:block hover:text-primary' to='/#price' onClick={handleAnchorClick}>
                    Cennik
                </Link>
                <Link className='text-foreground font-semibold hidden md:block hover:text-primary' to='/#location' onClick={handleAnchorClick}>
                    Lokalizacja
                </Link>
                <Link className='text-foreground font-semibold hidden md:block hover:text-primary' to='/#contact' onClick={handleAnchorClick}>
                    Kontakt
                </Link>
                <Link to='reservations/form'>
                    <Button className='w-min'>Zarezerwuj</Button>
                </Link>
                <Button className='w-min' onClick={ToggleTheme} variant='outline'>
                    <MoonRegular className='h-5 w-5 fill-primary hidden dark:flex' />
                    <SunRegular className='h-5 w-5 fill-primary flex dark:hidden' />
                </Button>
            </div>
        </div>
    );
};

export default Navbar;
