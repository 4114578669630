import axios from 'axios';
import { ErrorHandler } from '../errorhandler';
import { config } from '../configuration';
import { CreateReservationDto } from '../../dtos/CreateReservationDto';
import { GetReservationQuery } from '../../models/ReservationType';
import { Reservation } from '../../models/Reservation';

const GetPriceForDateRange = async (startDate: Date, endDate: Date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const startDateOnly = new Date(startDate);
    startDateOnly.setHours(0, 0, 0, 0);

    const endDateOnly = new Date(endDate);
    endDateOnly.setHours(0, 0, 0, 0);

    if (startDateOnly < currentDate || endDateOnly < startDateOnly) {
        return 0;
    }

    try {
        const response = await axios.get(`${config.api}reservations/price?start=${startDateOnly.toISOString()}&end=${endDateOnly.toISOString()}`);

        return response.data;
    } catch (error) {
        ErrorHandler(error);
    }
};

const CreateReservation = async (reservation: CreateReservationDto) => {
    try {        
        const response = await axios.post(`${config.api}reservations`, reservation);
        
        return response.data;
    } catch (error) {
        ErrorHandler(error);
    }
}

const GetReservations = async (query: GetReservationQuery) => {
    try {        
        const response = await axios.get(`${config.api}reservations?Type=${query.type}&SortBy=${query.sortBy}&IsDecsending=${query.isDecsending}&Page=${query.page}&PageNumber=${query.pageNumber}&PageSize=${query.pageSize}`);

        return response.data as Reservation[];
    } catch (error) {
        ErrorHandler(error);
    }

    return []
}

const UpdateReservation = async (reservation: Reservation) => {
    try {        
        const response = await axios.put(`${config.api}reservations/${reservation.id ?? 0}`, reservation);
        
        return response.data;
    } catch (error) {
        ErrorHandler(error);
    }
};

export { UpdateReservation, GetPriceForDateRange, CreateReservation, GetReservations };
