import { FunctionComponent, SVGProps } from 'react';
import Card from '../../components/card';

interface HomeCardProps {
    icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    title?: string;
    description: string;
}

const HomeCard = ({ icon: Icon, title, description }: HomeCardProps) => {
    return (
        <Card>
            <div className='flex flex-row gap-2 items-center justify-start pb-2'>
                <Icon className='h-5 w-5 fill-primary' />
                <span className='text-lg font-semibold'>{title}</span>
            </div>

            <span className='text-sm'>{description}</span>
        </Card>
    );
};

export default HomeCard;
