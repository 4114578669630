import HomeCard from './HomeCard';
import { ReactComponent as ClockSolid } from '../../assets/icons/clock-solid.svg';
import { ReactComponent as LightbulbSolid } from '../../assets/icons/lightbulb-solid.svg';
import { ReactComponent as LocationDotSolid } from '../../assets/icons/location-dot-solid.svg';
import { ReactComponent as ShieldHalvedSolid } from '../../assets/icons/shield-halved-solid.svg';
import { ReactComponent as TruckPlaneSolid } from '../../assets/icons/truck-plane-solid.svg';
import { ReactComponent as WarehouseSolid } from '../../assets/icons/warehouse-solid.svg';

const HomeAbout = () => {
    return (
        <div className='w-full flex flex-col justify-center py-8'>
            <span id='about-us' className='text-3xl text-primary mt-16 mb-4'>
                Dlaczego my?
            </span>

            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 justify-center py-4 text-start'>
                <HomeCard title='Doskonała lokalizacja' description='RoyalParking położony jest przy zjeździe z trasy s7' icon={LocationDotSolid} />
                <HomeCard title='Bezpieczeństwo' description='Cały teren parkingu objęty jest monitoringiem CCTV i ochroną 24/7' icon={ShieldHalvedSolid} />
                <HomeCard title='Bezpłatny transfer' description='Komfortowy transfer na lotnisko bez korków i nerwów w 4 minuty' icon={TruckPlaneSolid} />
                <HomeCard title='Całodobowy' description='Parkuj o każdej porze dnia i nocy, przez 7 dni w tygodniu' icon={ClockSolid} />
                <HomeCard title='Oświetlony' description='Oświetlony i utwardzony teren parkingu gwarantuje komfort i bezpieczeństwo twojego auta' icon={LightbulbSolid} />
                <HomeCard title='Garaże' description='Wkrótce dostępne' icon={WarehouseSolid} />
            </div>
        </div>
    );
};

export default HomeAbout;
