import { Link } from 'react-router-dom';
import Button from '../../components/button';
import Card from '../../components/card';
import HomePriceCard from './HomePriceCard';

const HomePrice = () => {
    return (
        <div className='w-full flex flex-col justify-center py-8'>
            <span id='price' className='text-3xl text-primary mt-16 mb-4'>
                Cennik
            </span>

            <Card className='bg-slate-900'>
                <div className='flex xl:flex-row flex-col justify-between items-center p-8'>
                    <div className='flex flex-col gap-4 my-8 text-start mx-16'>
                        <span className='lg:text-4xl text-3xl font-bold text-white'>Najtaniej w Warszawie!</span>
                        <Link to='reservations/form'>
                            <Button>Zarezerwuj</Button>
                        </Link>
                    </div>

                    <div className='lg:w-1/2 grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4'>
                        <HomePriceCard time='1 dzień' price='90 zł' />
                        <HomePriceCard time='2 dni' price='110 zł' />
                        <HomePriceCard time='3 dni' price='125 zł' />
                        <HomePriceCard time='4 dni' price='145 zł' />
                        <HomePriceCard time='5 dni' price='165 zł' />
                        <HomePriceCard time='6 dni' price='195 zł' />
                        <HomePriceCard time='7 dni' price='210 zł' />
                        <HomePriceCard time='8 dni' price='225 zł' />
                        <HomePriceCard time='9 dni' price='240 zł' />
                        <HomePriceCard time='10 dni' price='250 zł' />
                        <HomePriceCard time='11 dni' price='260 zł' />
                        <HomePriceCard time='12 dni' price='270 zł' />
                        <HomePriceCard time='13 dni' price='280 zł' />
                        <HomePriceCard time='14 dni' price='290 zł' />
                        <HomePriceCard time='Ponad 14 dni' price='10 zł / dzień' />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default HomePrice;
